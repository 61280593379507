import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import store from './store';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import AuthService from './services/AuthService';

axios.defaults.baseURL = process.env.REACT_APP_LEETSCRATCH_BASE_API_URL;
axios.defaults.headers.common = {'Authorization': `bearer ${AuthService.getToken()}`}

ReactDOM.render(
  //<React.StrictMode>
  <HttpsRedirect>
    <Provider store={store}>
        <App />
    </Provider>
  </HttpsRedirect>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
