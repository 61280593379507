import React, { useState } from 'react';
import './Login.sass';
import InputBase from '@material-ui/core/InputBase';
import AuthService from '../../services/AuthService';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
    //let username;
    const history = useHistory();

    const login = async () => {
        if (isFetching) {
            return;
        }

        try {
            setFetching(true);
            const result = await AuthService.login(password);
            const loginResult = result.data.data;
            localStorage.setItem ('token', loginResult.token);
            axios.defaults.headers.common = {'Authorization': `bearer ${loginResult.token}`}
            history.push('/');
        }
        catch (error) {
            setError("Login Failed.");
            setFetching(false);
        }
    }

    const [isFetching, setFetching] = useState(false);
    const [isError, setError] = useState(false);
    const [password, setPassword] = useState('');

    return (
        <div className="login">
            {/* <div>
                <InputBase  placeholder="Search..."
                            className="search-input"
                            value={username}>
                </InputBase>
            </div> */}
            <div>
                <InputBase  placeholder="Password"
                            className="search-input"
                            value={password}
                            onInput={e => setPassword(e.target.value)}>
                </InputBase>
            </div>
            {(isError && !isFetching) && <div>Login Failed!</div>}
            <div className="login-button" onClick={login}>Login!</div>

        </div>
    );
}

export default Login;