import React from "react";
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import './Header.sass';
import HelpIcon from '@material-ui/icons/Help';
import ReactTooltip from 'react-tooltip';
import ErrorIcon from '@material-ui/icons/Error';

const Header = () => {
  return (
    <div className="header">
        <div className="section left">
            <Link to="/" className="link">
                <Typography className="title" variant="h5" noWrap>
                    Evolution Tag 2
                </Typography>
            </Link>
            {/* <div className="links-container">
                <Link to="/about" className="link">
                    <div>
                        About
                    </div>
                </Link>
            </div> */}
        </div>
        <div className="section middle">
            {/* <SearchBar /> */}
        </div>
        <div className="section right">
            <Link to="/status" className="link" >
                <div className="header-icon" data-tip data-for="header-missing">
                    
                </div>
            </Link>
            <Link to="/about" className="link">
                <div className="header-icon" data-tip data-for="header-info">
                    About
                </div>
            </Link>
        </div>
    </div>
  );
}

export default Header;
