import React from "react";
import PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';
import './YoutubeEmbed.sass';

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
        <CircularProgress />
        <iframe
            src={`https://www.youtube.com/embed/${embedId}?modestbranding=1&autohide=1&showinfo=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
