import React from 'react';
import './Home.sass';
import backgroundimg from '../../images/PaladinArtCompressed_Small.jpg'
import Divider from '@material-ui/core/Divider';
import { useSelector } from 'react-redux';
import TuneIcon from '@material-ui/icons/Tune';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';
import { makeStyles } from '@material-ui/core/styles';
import { blue, green } from '@material-ui/core/colors';
import YoutubeEmbed from './YoutubeEmbed';

const Home = () => {
    /*const scratchcards = useSelector(state => state.scratchcardList.scratchcards);*/
    const [isVideoOpen, setIsVideoOpen] = React.useState(false);

    const openVideo = () => {
        setIsVideoOpen(true);
    };

    const useStyles = makeStyles((theme) => ({
        root: {
          '& > .fa': {
            margin: theme.spacing(2),
          },
        },
      }));

    React.useEffect(() => {
        const node = loadCSS(
          'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
          document.querySelector('#font-awesome-css'),
        );
    
        return () => {
          node.parentNode.removeChild(node);
        };
      }, []);

    return (
        <div className="home">
            <div className="main-content">
                <div className="social-icons-container">
                    <a href="https://twitter.com/evolutiontag2" target="_blank" className="white-background-container">
                        <div className="white-background"></div>
                        <Icon className="social-icon twitter fab fa-twitter-square" />
                    </a>
                    <a href="https://discord.gg/DNyAMePSAS" target="_blank" className="white-background-container">
                        <div className="white-background"></div>
                        <Icon className="social-icon discord fab fa-discord" />
                    </a>
                    <a href="https://www.reddit.com/r/evolutiontag/" target="_blank" className="white-background-container">
                        <div className="white-background"></div>
                        <Icon className="social-icon reddit fab fa-reddit-square" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCkiwQYD2O_SCAJHJ4h9uOyw" target="_blank" className="white-background-container">
                        <div className="white-background"></div>
                        <Icon className="social-icon youtube fab fa-youtube" />
                    </a>
                    <a href="https://store.steampowered.com/app/1750450/Evolution_Tag_2/" target="_blank" className="white-background-container">
                        <div className="white-background steam"></div>
                        <Icon className="social-icon steam fab fa-steam-square" />
                    </a>
                </div>
                {!isVideoOpen &&
                    <div className="main-button-container">
                        <Button variant="contained" color="primary" className="main-button" onClick={() => openVideo()}>WATCH VIDEO</Button>
                    </div>
                }  
                {isVideoOpen &&
                    <YoutubeEmbed embedId="ofhqi1H89i8" />
                }
            </div>
        </div>
    );
}

export default Home;