import React from 'react';
import './About.sass';

const About = () => {
    return (
        <div className="about">
            <div className="section">
                <div className="title">
                    Flying Turnip Studios
                </div>
                <div className="content">
                    Flying Turnip Studios is a company founded in 2021 with the goal of releasing a game with an innovative and refreshing angle on the traditional RTS/MOBA genre. The game is currently in alpha and open to testing!
                </div>
            </div>
            <div className="section contact">
                <div className="title">
                    Evolution Tag 2
                </div>
                <div className="content">
                    Evolution Tag 2 is the game currently being developed. Evolve, survive, build bases, control armies, and fight other players in an intense 10vs5 match between humans and the undead.
                </div>
            </div>
            <div className="section contact">
                <div className="title">
                    The Team
                </div>
                <div className="content">
                    The team currently consists of one developer based in Vancouver, Canada.
                </div>
            </div>
        </div>
    );
}

export default About;