import axios from 'axios';

class AuthService {
    static login(password) {
        return axios.post(`/signin`, {
            data: {
                password: password
            }
        });
    }

    static logout() {
        localStorage.clear();
        window.location.reload();
    }

    static getToken() {
        let token = localStorage.getItem('token');
        if (!token) {
            return null;
        }

        return token;
    }
}

export default AuthService;