import React, { PureComponent } from "react";
import Header from "./components/Header";
import About from "./components/About";
import Home from './components/Home';
import Login from './components/Login';
import backgroundimg from './images/PaladinArtCompressed_Small.jpg'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';

export default class App extends PureComponent {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/about" component={About} />
          </Switch>
        </div>
      </Router>
    );
  }
}
