import { createStore, applyMiddleware } from 'redux';
import allReducers from './reducers/rootReducer';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const devToolsAndMiddleware = process.env.NODE_ENV === 'production'
    ? applyMiddleware(thunk, sagaMiddleware)
    : composeWithDevTools(applyMiddleware(thunk, sagaMiddleware))

const store = createStore(
    allReducers,
    devToolsAndMiddleware
);

sagaMiddleware.run(rootSaga)

export default store;