import { call, put, takeLatest, delay } from 'redux-saga/effects';
import axios from 'axios';

function* addSuggestion(action) {
    try {
        const response = yield call(() => axios.post('/suggestions', action.payload.suggestion));
        yield put({type: 'ADD_SUGGESTION_SUCCEEDED', suggestion: response.data.data});
        yield delay(2000);
        yield put({type: 'ADD_SUGGESTION_TRANSITION'});
        yield delay(1000);
        if (action.payload.dependencies.location.pathname.includes('missing')) {
            yield call(() => {action.payload.dependencies.history.push('/status')});
        }

    } catch (e) {
        yield put({type: 'ADD_SUGGESTION_FAILED', error: e.response.data});
        yield delay(2000);
        yield put({type: 'ADD_SUGGESTION_TRANSITION'});
        yield delay(1000);
        yield put({type: 'ADD_SUGGESTION_RESET'});
    }
}

export default function* suggestionSaga() {
    yield takeLatest('ADD_SUGGESTION_REQUESTED', addSuggestion);
}